import { useNavigate } from 'react-router-dom';

import { Typography, Card, CardActionArea, CardContent, Grid } from '@mui/material';

import { RegistrationStepsRoutes, Routes } from '../constants';

import { useAuthContext } from '../../auth/AuthContext';

import SignupLayout from '../SignupLayout';

import type React from 'react';

export const SelectAccountType: React.FC = () => {
  const { user } = useAuthContext();
  const isAuthenticated = !!user;
  const navigate = useNavigate();

  const handleSelectAccountType = async (type: 'personal' | 'organization') => {
    if (!isAuthenticated) {
      return;
    }

    if (type === 'personal') {
      navigate(RegistrationStepsRoutes.SECURE_ACCOUNT);

      return;
    }

    navigate(RegistrationStepsRoutes.CREATE_ORGANIZATION); // Replace with the next step in your registration flow
  };

  const handleGoBack = () => {
    navigate(Routes.SIGN_UP);
  };

  return (
    <SignupLayout progress={40} onBack={handleGoBack}>
      <Grid container>
        <Grid item mb={4} xs={12}>
          <Typography gutterBottom variant="h5">
            Select the type of account
          </Typography>
          <Typography gutterBottom color="grey" variant="subtitle1">
            Select the account type that fits your needs.
          </Typography>
        </Grid>
        <Grid container sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
          <Grid item xs={12}>
            <Card
              sx={{ border: '1px solid #D7DAFE', borderRadius: '12px' }}
              variant="outlined"
              onClick={() => {
                handleSelectAccountType('personal');
              }}
            >
              <CardActionArea
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  px: 1,
                }}
              >
                <CardContent>
                  <Typography variant="h6">Personal</Typography>
                  <Typography color="grey" sx={{ fontSize: 14 }} variant="body2">
                    For individuals that want secure wallets for storing their crypto assets and secure interaction in
                    the Web3 space.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card
              sx={{
                border: '1px solid #D7DAFE',
                borderRadius: '12px',
              }}
              variant="outlined"
              onClick={() => {
                handleSelectAccountType('organization');
              }}
            >
              <CardActionArea
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  px: 1,
                }}
              >
                <CardContent>
                  <Typography variant="h6">Organization</Typography>
                  <Typography color="grey" sx={{ fontSize: 14 }} variant="body2">
                    For teams and business that need high secure Web3 wallets, that are working together as a team and
                    want to have multiple approvers.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </SignupLayout>
  );
};

export default SelectAccountType;
