import { GridActionsCellItem } from '@mui/x-data-grid';

import Chip from '@mui/material/Chip';

import Box from '@mui/material/Box';

import { Typography } from '@mui/material';

import { format } from 'date-fns';

import type { TTransaction } from '../../../redux/api';

import type { GridColDef } from '@mui/x-data-grid/models';

export const DATE: GridColDef<TTransaction> = {
  field: 'createdAt',
  minWidth: 300,
  headerName: 'Date',
  disableColumnMenu: true,
  valueGetter: (value) => {
    return format(value, 'E MMM dd yyyy HH:MM:SS');
  },
};

export const ACTION: GridColDef<TTransaction> = {
  field: 'action',
  minWidth: 300,
  headerName: 'Action',
  disableColumnMenu: true,
};

export const INITIATOR: GridColDef<TTransaction> = {
  field: 'initiator',
  minWidth: 300,
  headerName: 'Initiator',
  disableColumnMenu: true,
  flex: 1,
  renderCell: ({ value }) => {
    if (!value) {
      return '-';
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography sx={{ fontSize: '14px', fontWeight: '500', color: '#01063C' }}>{value.name}</Typography>
        <Typography sx={{ fontSize: '11px', fontWeight: '500', color: '#949494' }}>{value.organization}</Typography>
      </Box>
    );
  },
};
export const STATUS: GridColDef<TTransaction> = {
  field: 'status',
  minWidth: 300,
  headerName: 'Status',
  disableColumnMenu: true,
  renderCell: ({ value }) => <Chip label={String(value)} />,
};

export const ACTIONS: GridColDef<TTransaction> = {
  field: 'actions',
  type: 'actions',
  disableColumnMenu: true,
  sortable: false,
  getActions: ({ row }) => {
    const actions = [
      <GridActionsCellItem key="details" showInMenu data-cell-id="details" label="Details" sx={{ minWidth: 160 }} />,
    ];

    if (row.status === ('created' as TTransaction['status'])) {
      actions.push(
        <GridActionsCellItem key="reject" showInMenu data-cell-id="approve" label="Approve" />,
        <GridActionsCellItem
          key="approve"
          showInMenu
          data-cell-id="reject"
          label="Reject"
          sx={{ color: 'error.main' }}
        />,
      );
    }

    return actions;
  },
};

export default [DATE, INITIATOR, STATUS, ACTIONS];
