import { useNavigate } from 'react-router-dom';
import { Button, Typography, Paper, CircularProgress } from '@mui/material';

import { RegistrationStepsRoutes } from '../constants';
import { EApproveLoginOnAppStatus, useApproveLoginOnAppMutation } from '../../redux/api';

import SignupLayout from '../SignupLayout';

import type React from 'react';

const LoginOnAppRejected: React.FC = () => {
  const navigate = useNavigate();
  const [approveLoginOnApp, { isLoading }] = useApproveLoginOnAppMutation();

  const handleApprove = async () => {
    await approveLoginOnApp({ status: EApproveLoginOnAppStatus.Initialize });

    navigate(RegistrationStepsRoutes.APPROVE_LOGIN_ON_APP, { replace: true });
  };

  return (
    <SignupLayout progress={100}>
      <Paper elevation={0} sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Typography color="#BA1A1A" variant="h5">
          Log in rejected
        </Typography>
        <Typography variant="subtitle2">User log in rejected on mobile app.</Typography>
        <Button
          fullWidth
          disabled={isLoading}
          startIcon={isLoading ? <CircularProgress size="small" /> : undefined}
          sx={{ borderRadius: '24px', textTransform: 'none', mt: 2 }}
          variant="contained"
          onClick={handleApprove}
        >
          Log in again
        </Button>
      </Paper>
    </SignupLayout>
  );
};

export default LoginOnAppRejected;
