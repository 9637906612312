import {
  DialogTitle,
  DialogContent,
  LinearProgress,
  DialogContentText,
  TextField,
  Select,
  MenuItem,
  DialogActions,
  Button,
  Typography,
  Tooltip,
} from '@mui/material';

import { QuestionMarkOutlined } from '@mui/icons-material';

import { STEP_NUMBERS, type TFormElements } from '../CreateWalletFlow';

import { EWalletType } from '../../../../redux/api';

import type TCreateWalletStepsProps from '../../../../types/TCreateWalletStepsProps';

import type React from 'react';

import type TFormElement from '../../../../types/TFormElement';

const WalletNameStep: React.FC<TCreateWalletStepsProps> = ({ onNext, onClose, walletData, setWalletData }) => {
  return (
    <form
      onSubmit={(event: React.FormEvent<TFormElement<TFormElements>>) => {
        event.preventDefault();

        const { walletName, type } = event.currentTarget.elements;

        setWalletData?.((data) => ({ ...data, walletName: walletName.value, type: Number(type.value) }));

        onNext(STEP_NUMBERS.manageAccess);
      }}
    >
      <DialogTitle id="alert-dialog-title" variant="headlineLarge">
        Create wallet
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <LinearProgress value={5} variant="determinate" />
        <DialogContentText id="alert-dialog-description" my={2} variant="bodyLarge">
          Select your wallet name and pick the wallet type that works best for your use case and intention.
        </DialogContentText>
        <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }} variant="labelLarge">
          Type of wallet
          <Tooltip title="More types will be available soon.">
            <QuestionMarkOutlined sx={{ fontSize: 16, borderRadius: 100, border: '1px solid #949494' }} />
          </Tooltip>
        </Typography>
        <Select defaultValue={walletData?.type} id="demo-simple-select" name="type" sx={{ fontSize: 14, mb: 2 }}>
          <MenuItem value={EWalletType.WALLET_TYPE_MPC}>MPC Wallet</MenuItem>
        </Select>
        <Typography variant="labelLarge">Name your wallet</Typography>
        <TextField
          autoFocus
          fullWidth
          required
          defaultValue={walletData?.walletName}
          id="walletName"
          name="walletName"
          placeholder="Type a name for your new wallet"
          sx={{
            '& .MuiInputBase-input': {
              fontSize: 16,
            },
          }}
          type="text"
          variant="outlined"
        />
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button sx={{ ml: 0, mr: 'auto', color: 'grey.100' }} variant="text" onClick={onClose}>
          Close
        </Button>
        <Button autoFocus type="submit" variant="contained">
          Next step
        </Button>
      </DialogActions>
    </form>
  );
};

export default WalletNameStep;
