import { DoneOutline } from '@mui/icons-material';
import { DialogContent, DialogContentText, Typography, Button } from '@mui/material';

import { STEP_NUMBERS, defaultWalletData } from '../CreateWalletFlow';

import type TCreateWalletStepsProps from '../../../../types/TCreateWalletStepsProps';

import type React from 'react';

const WalletCreatedStep: React.FC<TCreateWalletStepsProps & { walletAddress?: string; onGoToWallet(): void }> = ({
  onNext,
  setWalletData,
  walletAddress,
  onGoToWallet,
}) => {
  return (
    <DialogContent>
      <DialogContentText
        component="div"
        id="alert-dialog-description"
        sx={{ fontSize: '16px', display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}
      >
        <DoneOutline color="primary" sx={{ width: 64, height: 64 }} />
        <Typography variant="body2">Wallet created successfully!</Typography>
        <Typography color="secondary" fontSize="14px" variant="body2">
          {walletAddress}
        </Typography>
        <Button autoFocus variant="contained" onClick={onGoToWallet}>
          Go to my new wallet
        </Button>
        <Button
          sx={{ color: 'grey.100' }}
          variant="text"
          onClick={() => {
            setWalletData?.(defaultWalletData);
            onNext(STEP_NUMBERS.walletName);
          }}
        >
          Create another wallet
        </Button>
      </DialogContentText>
    </DialogContent>
  );
};

export default WalletCreatedStep;
