import { DialogTitle, DialogContent, TextField, DialogActions, Button, Typography } from '@mui/material';

import type { TCreateTransactionRequest } from '../../../../redux/api';

import type React from 'react';

import type TFormElement from '../../../../types/TFormElement';

export type TSendDialogProps = {
  onClose: () => void;
  onSend: (data: Omit<TCreateTransactionRequest, 'WalletID'>) => void;
};

type TFormElements = {
  WalletID?: HTMLInputElement;
  toAddress?: HTMLInputElement;
  amount?: HTMLInputElement;
  gasLimit?: HTMLInputElement;
  gasPrice?: HTMLInputElement;
};

const SendDialog: React.FC<TSendDialogProps> = ({ onSend, onClose }) => {
  return (
    <form
      onSubmit={(event: React.FormEvent<TFormElement<TFormElements>>) => {
        event.preventDefault();

        const { toAddress, amount, gasLimit, gasPrice } = event.currentTarget.elements;

        if (!toAddress?.value || !amount?.value || !gasLimit?.value || !gasPrice?.value) {
          return;
        }

        onSend({
          toAddress: toAddress.value,
          amount: Number(amount.value),
          gasLimit: Number(gasLimit.value),
          gasPrice: gasPrice.value,
          nonce: 0,
        });
      }}
    >
      <DialogTitle id="alert-dialog-title" variant="headlineLarge">
        Send
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="labelLarge">Address</Typography>
        <TextField
          autoFocus
          fullWidth
          required
          defaultValue=""
          id="toAddress"
          name="toAddress"
          placeholder="Type address"
          size="small"
          type="text"
          variant="outlined"
        />

        <Typography mt={2} variant="labelLarge">
          Amount
        </Typography>
        <TextField
          fullWidth
          required
          defaultValue=""
          id="amount"
          name="amount"
          placeholder="Type amount"
          size="small"
          type="text"
          variant="outlined"
        />

        <Typography mt={2} variant="labelLarge">
          Gas limit
        </Typography>
        <TextField
          fullWidth
          required
          defaultValue="0"
          id="gasLimit"
          name="gasLimit"
          placeholder="Type gas limit"
          size="small"
          type="text"
          variant="outlined"
        />

        <Typography mt={2} variant="labelLarge">
          Gas price
        </Typography>
        <TextField
          fullWidth
          required
          defaultValue="0"
          id="gasPrice"
          name="gasPrice"
          placeholder="Type gas price"
          size="small"
          type="text"
          variant="outlined"
        />
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button sx={{ ml: 0, mr: 'auto', color: 'grey.100' }} variant="text" onClick={onClose}>
          Close
        </Button>
        <Button autoFocus type="submit" variant="contained">
          Send
        </Button>
      </DialogActions>
    </form>
  );
};

export default SendDialog;
