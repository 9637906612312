import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

import { STEP_NUMBERS } from '../CreateWalletFlow';

import type TCreateWalletStepProps from '../../../../types/TCreateWalletStepsProps';

import type React from 'react';

const WelcomeTo: React.FC<TCreateWalletStepProps> = ({ onNext, onClose }) => {
  return (
    <>
      <DialogTitle id="alert-dialog-title" variant="headlineLarge">
        Welcome to deVaulty
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" variant="bodyLarge">
          Create your first wallet, authorize your team, and start secure fast, and efficient interaction with Web3
          space. Choose &quot;Create wallet&quot; to begin your Web3 journey.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button sx={{ ml: 0, mr: 'auto', fontSize: 14, color: 'grey.100' }} variant="text" onClick={onClose}>
          Skip for now
        </Button>
        <Button
          autoFocus
          variant="contained"
          onClick={() => {
            onNext(STEP_NUMBERS.walletName);
          }}
        >
          Create wallet
        </Button>
      </DialogActions>
    </>
  );
};

export default WelcomeTo;
