import {
  DialogTitle,
  DialogContent,
  LinearProgress,
  DialogContentText,
  DialogActions,
  Button,
  Box,
  Typography,
} from '@mui/material';

import { useCallback, useEffect, useState } from 'react';

import { STEP_NUMBERS } from '../../CreateWalletFlow';

import UserEntry from './UserEntry';

import SearchUsersField from './SearchUsersField';

import { useAuthContext } from '../../../../../auth/AuthContext';

import type React from 'react';

import type TCreateWalletStepsProps from '../../../../../types/TCreateWalletStepsProps';

export type TWalletCreateUser = {
  name: string;
  email: string;
  role: string;
  invited?: boolean;
};

const ManageAccessStep: React.FC<TCreateWalletStepsProps> = ({ onClose, onNext, setWalletData }) => {
  const [users, setUsers] = useState<TWalletCreateUser[]>([]);

  const { user } = useAuthContext();

  const handleUserDelete = useCallback((user: TWalletCreateUser) => {
    setUsers((users) => users.filter(({ email }) => user.email !== email));
  }, []);

  const handleUserEdit = useCallback((user: TWalletCreateUser) => {
    setUsers((users) => users.map((item) => (user.email !== item.email ? user : item)));
  }, []);

  const handleAddUser = useCallback((user: TWalletCreateUser) => {
    setUsers((users) => [...users, user]);
  }, []);

  useEffect(() => {
    if (!users.length) {
      setUsers([{ name: String(user?.displayName), email: String(user?.email), role: 'Owner' }]);
    }
  }, [user?.displayName, user?.email, users.length]);

  return (
    <>
      <DialogTitle id="alert-dialog-title" variant="headlineLarge">
        Create wallet
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <LinearProgress value={50} variant="determinate" />
        <DialogContentText id="alert-dialog-description" my={2} variant="bodyLarge">
          Invite your team members to operate, initiate, approve, or view your wallet. Pick the role for the user and
          select next.
        </DialogContentText>
        <Typography variant="labelLarge">Search name or email</Typography>
        <SearchUsersField onAddUser={handleAddUser} />
        <Box>
          {users.map((user_) => (
            <UserEntry
              key={user_.email}
              isDeletable={user_.email !== user?.email || users.length > 1}
              isEditable={user_.email !== user?.email || users.length > 1}
              isInvited={user_.invited}
              user={user_}
              onDelete={handleUserDelete}
              onUserEdit={handleUserEdit}
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button sx={{ ml: 0, mr: 'auto', textTransform: 'none' }} variant="text" onClick={onClose}>
          Close
        </Button>
        <Button
          autoFocus
          sx={{ textTransform: 'none' }}
          variant="contained"
          onClick={() => {
            setWalletData?.((data) => ({ ...data, users }));
            onNext(STEP_NUMBERS.threshold);
          }}
        >
          Next step
        </Button>
      </DialogActions>
    </>
  );
};

export default ManageAccessStep;
