import { useNavigate } from 'react-router-dom';

import { Typography, Paper, Grid, CircularProgress } from '@mui/material';

import { useEffect, useState } from 'react';

import { getFirestore, collection, addDoc, serverTimestamp, orderBy, onSnapshot, query } from 'firebase/firestore';

import { RegistrationStepsRoutes } from '../constants';

import SignupLayout from '../SignupLayout';

import { FIREBASE_MESSAGES_DB } from '../../auth/firebase';

import { EApproveLoginOnAppStatus } from '../../redux/api';

import type React from 'react';

const sendApprovalRequest = async (userId: string) => {
  const db = getFirestore();
  const requestsRef = collection(db, FIREBASE_MESSAGES_DB);

  try {
    const docRef = await addDoc(requestsRef, {
      userId,
      timestamp: serverTimestamp(),
      status: 'pending',
    });

    return docRef.id; // Return the request ID
  } catch (error) {
    console.error('Error sending approval request:', error);
  }
};

const ApproveLoginOnApp: React.FC = () => {
  const navigate = useNavigate();
  const [requestId, setRequestId] = useState<string | null>(null);

  useEffect(() => {
    const keyId = sessionStorage.getItem('keyId');

    if (!keyId) {
      navigate(RegistrationStepsRoutes.APPROVE_ACCOUNT_CONNECT);
    }

    async function sendRequestAndSetId(userId: string) {
      const id = await sendApprovalRequest(userId);

      setRequestId(id ?? '');
    }

    if (keyId && !requestId) {
      sendRequestAndSetId(keyId);
    }
  }, [navigate, requestId]);

  useEffect(() => {
    const db = getFirestore();
    const responsesRef = collection(db, FIREBASE_MESSAGES_DB);
    const q = query(responsesRef, orderBy('timestamp', 'desc'));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const response = querySnapshot.docs.find((doc) => doc.id === requestId);

      if (response && response.data().status === EApproveLoginOnAppStatus.Rejected) {
        navigate(RegistrationStepsRoutes.LOGIN_ON_APP_REJECTED);
      }

      if (response && response.data().status === EApproveLoginOnAppStatus.Approved) {
        navigate(RegistrationStepsRoutes.LOGIN_ON_APP_APPROVED);
      }
    });

    // Cleanup subscription on unmount
    return () => {
      unsubscribe();
    };
  }, [navigate, requestId]);

  const handleGoBack = () => {
    navigate(RegistrationStepsRoutes.APPROVE_ACCOUNT_CONNECT);
  };

  return (
    <SignupLayout progress={90} onBack={handleGoBack}>
      <Paper elevation={0} sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Typography variant="h5">Approve login on deVaulty app</Typography>
        <Typography variant="body2">You are one step away from the finish line!</Typography>
        <Typography variant="body2">Approve the login request on deVaulty to unleash the power of dMPC.</Typography>
        <Grid item textAlign="center">
          <CircularProgress />
          <Typography variant="subtitle2">Waiting for approval...</Typography>
        </Grid>
      </Paper>
    </SignupLayout>
  );
};

export default ApproveLoginOnApp;
