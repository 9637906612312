import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

import type { SerializedError } from '@reduxjs/toolkit';

import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import type TCreateWalletStepProps from '../../../../types/TCreateWalletStepsProps';

import type React from 'react';

const Error: React.FC<TCreateWalletStepProps & { error: FetchBaseQueryError | SerializedError | undefined }> = ({
  onClose,
}) => {
  return (
    <>
      <DialogTitle id="alert-dialog-title" variant="headlineLarge">
        Something went wrong
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" variant="bodyLarge">
          Something went wrong!
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          autoFocus
          sx={{ color: 'grey.100' }}
          variant="text"
          onClick={() => {
            onClose();
          }}
        >
          Close
        </Button>
      </DialogActions>
    </>
  );
};

export default Error;
