import safeJsonParse from './safeJsonParse';

import type { User } from 'firebase/auth';

export default function getSessionToken() {
  const user = localStorage.getItem('user');

  if (!user) {
    return null;
  }

  //TODO fix type
  const storedUser = safeJsonParse<User & { stsTokenManager: { accessToken: string } }>(user);

  if (!storedUser) {
    return null;
  }

  return storedUser.stsTokenManager.accessToken;
}
