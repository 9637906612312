import { useNavigate } from 'react-router-dom';

import { Paper, Button, Typography } from '@mui/material';

import { Google } from '@mui/icons-material';

import { RegistrationStepsRoutes, Routes } from '../constants';
import usePageTitle from '../../hooks/hooks';
import SignupLayout from '../SignupLayout';
import { useAuthContext } from '../../auth/AuthContext';

export const RegistrationPage = () => {
  const navigate = useNavigate();

  usePageTitle('deVaulty::Registration');

  const { googleAuth } = useAuthContext();

  const handleGoogleSignIn = async () => {
    try {
      const isSuccess = await googleAuth();

      if (isSuccess) {
        navigate(RegistrationStepsRoutes.SELECT_ACCOUNT_TYPE);
      }
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  return (
    <SignupLayout progress={5}>
      <Paper elevation={0} sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Typography variant="h5">Create a deVaulty account</Typography>

        <Button
          fullWidth
          color="primary"
          startIcon={<Google />}
          sx={{ borderRadius: 0.5, textTransform: 'none' }}
          variant="contained"
          onClick={handleGoogleSignIn}
        >
          Sign In with Google
        </Button>

        <Typography color="text.secondary" fontSize="14px" variant="body2">
          Already have an account? <a href={Routes.LOGIN}>Log in</a>
        </Typography>
      </Paper>
    </SignupLayout>
  );
};
