import { Paper, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Google } from '@mui/icons-material';

import usePageTitle from '../../hooks/hooks';

import { Routes } from '../constants';

import SignupLayout from '../SignupLayout';

import { useAuthContext } from '../../auth/AuthContext';

import type React from 'react';

const LoginPage: React.FC = () => {
  usePageTitle('deVaulty::Log in');

  const navigate = useNavigate();

  const { googleAuth } = useAuthContext();

  const handleGoogleSignIn = async () => {
    try {
      const isSuccess = await googleAuth();

      if (isSuccess) {
        navigate(Routes.APP);
      }
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  return (
    <SignupLayout>
      <Paper elevation={0} sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Typography variant="h5">Welcome back!</Typography>

        <Button
          fullWidth
          color="primary"
          startIcon={<Google />}
          sx={{ borderRadius: 0.5, textTransform: 'none' }}
          variant="contained"
          onClick={handleGoogleSignIn}
        >
          Sign In with Google
        </Button>

        <Typography color="text.secondary" fontSize="14px" variant="body2">
          Don&apos;t have an account? <a href={Routes.SIGN_UP}>Sign up</a>
        </Typography>
      </Paper>
    </SignupLayout>
  );
};

export default LoginPage;
