import { useNavigate } from 'react-router-dom';
import { Button, Typography, Paper, Grid, Box, Divider, CircularProgress } from '@mui/material';

import { useEffect } from 'react';

import { RegistrationStepsRoutes } from '../constants';
import { useConnectAccountQuery } from '../../redux/api';

import SignupLayout from '../SignupLayout';

import type React from 'react';

const ApproveAccountConnect: React.FC = () => {
  const navigate = useNavigate();

  // Extract the nicknameOrKeyId from URL parameters
  const urlParams = new URLSearchParams(window.location.search);
  const nicknameOrKeyId = urlParams.get('nicknameOrKeyId') ?? '';

  const {
    isLoading: isLoadingAccount,
    data,
    isError,
  } = useConnectAccountQuery({ nicknameOrKeyId }, { skip: !nicknameOrKeyId });

  useEffect(() => {
    if (!nicknameOrKeyId) {
      navigate(RegistrationStepsRoutes.CONNECT_ACCOUNT);

      return;
    }
  }, [isError, isLoadingAccount, navigate, nicknameOrKeyId]);

  const handleApprove = async () => {
    sessionStorage.setItem('keyId', data?.keyId ?? '');
    navigate(RegistrationStepsRoutes.APPROVE_LOGIN_ON_APP);
  };

  if (isLoadingAccount) {
    return (
      <SignupLayout>
        <Grid
          container
          sx={{
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'left',
          }}
        >
          <CircularProgress />
        </Grid>
      </SignupLayout>
    );
  }

  const handleGoBack = () => {
    navigate(RegistrationStepsRoutes.CONNECT_ACCOUNT);
  };

  if (isError) {
    return (
      <SignupLayout progress={80} onBack={handleGoBack}>
        <Paper elevation={0} sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Typography
            sx={{
              margin: '16px 0',
            }}
            variant="h5"
          >
            User is not found
          </Typography>
          <Button
            fullWidth
            sx={{ borderRadius: '24px', textTransform: 'none', mt: 2 }}
            variant="contained"
            onClick={handleGoBack}
          >
            Try again
          </Button>
        </Paper>
      </SignupLayout>
    );
  }

  return (
    <SignupLayout progress={80} onBack={handleGoBack}>
      <Paper elevation={0} sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Typography
          sx={{
            margin: '16px 0',
          }}
          variant="h5"
        >
          User found!
        </Typography>
        <Typography
          sx={{
            margin: '16px 0 32px',
          }}
          variant="subtitle2"
        >
          Please check on deVaulty that your Nickname and Key ID are as shown below.
        </Typography>
        <Box sx={{ background: '#FAF8FF', borderRadius: 1.5, px: 2, py: 1 }}>
          <Typography sx={{ color: '#45464F', fontSize: '12px', fontWeight: 500 }}>Nickname</Typography>
          <Typography noWrap variant="body1">
            {data?.nickname}
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Typography sx={{ color: '#45464F', fontSize: '12px', fontWeight: 500 }}>keyId</Typography>
          <Typography noWrap variant="body1">
            {data?.keyId}
          </Typography>
        </Box>
        <Button
          fullWidth
          sx={{ borderRadius: '24px', textTransform: 'none', mt: 2 }}
          variant="contained"
          onClick={handleApprove}
        >
          Approve and finish setup
        </Button>
      </Paper>
    </SignupLayout>
  );
};

export default ApproveAccountConnect;
