import { DialogTitle, DialogContent, DialogContentText, CircularProgress, Typography } from '@mui/material';

import type React from 'react';

const CreatingWalletStep: React.FC = () => {
  return (
    <>
      <DialogTitle id="alert-dialog-title" variant="headlineLarge">
        Welcome to deVaulty
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          component="div"
          id="alert-dialog-description"
          sx={{ fontSize: '16px', textAlign: 'center', py: 6 }}
        >
          <CircularProgress />
          <Typography variant="body2">Creating wallet...</Typography>
        </DialogContentText>
      </DialogContent>
    </>
  );
};

export default CreatingWalletStep;
