import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, TextField, Typography, Paper } from '@mui/material';

import { RegistrationStepsRoutes } from '../constants';

import SignupLayout from '../SignupLayout';

import type React from 'react';

import type TFormElement from '../../types/TFormElement';

type TFormFields = {
  nicknameOrKeyId: HTMLInputElement;
};

const ConnectAccount: React.FC = () => {
  const navigate = useNavigate();

  const [fieldError, setFieldError] = useState('');

  const handleSubmit = async (event: React.FormEvent<TFormElement<TFormFields>>) => {
    event.preventDefault();

    const {
      nicknameOrKeyId: { value },
    } = event.currentTarget.elements;

    if (!value) {
      return;
    }

    if (!Boolean(value.match(/[a-zA-Z0-9\_]{4,}/))) {
      setFieldError('min 4 letters, numbers, underscore');

      return;
    }

    setFieldError('');

    navigate(RegistrationStepsRoutes.APPROVE_ACCOUNT_CONNECT + `?nicknameOrKeyId=${encodeURIComponent(value)}`);
  };

  const handleGoBack = () => {
    navigate(RegistrationStepsRoutes.SECURE_ACCOUNT);
  };

  return (
    <SignupLayout progress={80} onBack={handleGoBack}>
      <Paper elevation={0} sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Typography variant="h5">Connect your new account with your personal key</Typography>
        <Typography
          sx={{
            fontSize: '14px',
          }}
          variant="body2"
        >
          Type your Nickname or Key ID.
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            error={!!fieldError}
            helperText={fieldError}
            id="nicknameOrKeyId"
            label="Nickname or Key ID"
            name="nicknameOrKeyId"
            size="small"
            sx={{
              marginBottom: '8px',
              borderRadius: '4px',
            }}
            variant="outlined"
          />
          <Button
            fullWidth
            sx={{ borderRadius: '24px', textTransform: 'none', mt: 2 }}
            type="submit"
            variant="contained"
          >
            Next
          </Button>
        </form>
      </Paper>
    </SignupLayout>
  );
};

export default ConnectAccount;
