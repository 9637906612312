import { createContext, useContext } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';

import { GoogleAuthProvider, signInWithPopup, type Auth, type User } from 'firebase/auth';

import { auth } from './firebase';

type TAuthState = {
  user: User | null | undefined;
};

type TAuthContext = {
  user: TAuthState['user'];
  signOut: () => Promise<void>;
  googleAuth: () => Promise<boolean>;
  isLoading: boolean;
  auth: Auth;
};

export const AuthContext = createContext<TAuthContext | null>(null);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, isLoading] = useAuthState(auth, {
    onUserChanged: async (user) => {
      if (user) {
        localStorage.setItem('user', JSON.stringify(user));
      } else {
        localStorage.removeItem('user');
      }
    },
  });

  const signOut = async () => {
    await auth.signOut();
  };

  const googleAuth = async () => {
    const provider = new GoogleAuthProvider();

    try {
      await signInWithPopup(auth, provider);

      return true;
    } catch (error) {
      console.error('Login failed:', error);

      return false;
    }
  };

  return <AuthContext.Provider value={{ user, signOut, isLoading, auth, googleAuth }}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }

  return context;
};
