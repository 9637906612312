import { DialogTitle, DialogContent, DialogActions, Button, Typography, Box } from '@mui/material';

import { format } from 'date-fns/format';

import type { TTransaction } from '../../../../redux/api';

import type React from 'react';

export type TTransactionDetailsProps = {
  onClose: () => void;
  transaction: TTransaction | null;
};

const TransactionDetails: React.FC<TTransactionDetailsProps> = ({ onClose, transaction }) => {
  if (!transaction) {
    return null;
  }

  const { toAddress, gasPrice, gasLimit, amount, createdAt } = transaction;

  return (
    <Box>
      <DialogTitle id="alert-dialog-title" variant="headlineLarge">
        Transaction details
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="subtitle2">Address</Typography>
        <Typography mb={2} variant="labelLarge">
          {toAddress}
        </Typography>

        <Typography variant="subtitle2">Amount</Typography>
        <Typography mb={2} variant="labelLarge">
          {amount}
        </Typography>

        <Typography variant="subtitle2">Gas price</Typography>
        <Typography mb={2} variant="labelLarge">
          {gasPrice}
        </Typography>

        <Typography variant="subtitle2">Gas limit</Typography>
        <Typography mb={2} variant="labelLarge">
          {gasLimit}
        </Typography>

        <Typography variant="subtitle2">Date</Typography>
        <Typography variant="labelLarge">{format(createdAt, 'E MMM dd yyyy HH:MM:SS')}</Typography>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button sx={{ ml: 0, mr: 'auto', color: 'grey.100' }} variant="text" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Box>
  );
};

export default TransactionDetails;
