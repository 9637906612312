import { Outlet, useNavigate } from 'react-router';

import { useEffect } from 'react';

import { Routes } from '../components/constants';
import LoadingPage from '../components/LoadingPage';
import Layout from './Layout/Layout';
import { useAuthContext } from '../auth/AuthContext';

export function App() {
  const navigate = useNavigate();
  const { user, isLoading } = useAuthContext();
  const isAuthenticated = !!user;

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      navigate(Routes.LOGIN, { replace: true });

      return;
    }
  }, [isAuthenticated, isLoading, navigate]);

  if (isAuthenticated) {
    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  }

  return <LoadingPage />;
}

export default App;
