import { useNavigate } from 'react-router-dom';
import { Typography, Paper } from '@mui/material';

import { useEffect } from 'react';

import { Routes } from '../constants';

import SignupLayout from '../SignupLayout';

import type React from 'react';

const LoginOnAppApproved: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate(`${Routes.APP}/?firstLogin=true`, { replace: true });
    }, 2000);
  }, [navigate]);

  return (
    <SignupLayout progress={100}>
      <Paper elevation={0} sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Typography variant="h5">Log in approved</Typography>
        <Typography variant="subtitle2">Redirecting to deVaulty...</Typography>
      </Paper>
    </SignupLayout>
  );
};

export default LoginOnAppApproved;
