import { useNavigate } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { Typography, Grid, Button, Checkbox, FormControlLabel, Alert, Snackbar, Box } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { useCallback, useState } from 'react';

import { RegistrationStepsRoutes } from '../constants';

import SignupLayout from '../SignupLayout';

import type React from 'react';

export const SecureAccount: React.FC = () => {
  const navigate = useNavigate();

  const [isChecked, setIsChecked] = useState(false);
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  const IOS_LINK = process.env.REACT_APP_IOS_APP_LINK ?? '';
  const ANDROID_LINK = process.env.REACT_APP_ANDROID_APP_LINK ?? '';

  const handleNext = () => {
    navigate(RegistrationStepsRoutes.CONNECT_ACCOUNT);
  };

  const handleCopyToClipboard = useCallback((link: string) => {
    navigator.clipboard.writeText(link).then(() => {
      setSnackbarOpen(true);
    });
  }, []);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <SignupLayout progress={70} onBack={handleGoBack}>
      <Grid container>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h5">
            Securing your account
          </Typography>
          <Typography gutterBottom color="#45464F" fontSize={14} mb={3} variant="body2">
            Install dMPC approval app on a mobile device and follow the instructions on the app.
          </Typography>
          <Typography gutterBottom color="#45464F" fontSize={14} variant="body2">
            After you have created your personal key in dMPC approval app, click next.
          </Typography>
        </Grid>
        <Grid container item flexWrap="nowrap" justifyContent="center" my={2} spacing={2} textAlign="center" xs={12}>
          <Grid item xs={6}>
            <Box sx={{ border: '1px solid #D7DAFE', borderRadius: '12px' }}>
              <Typography gutterBottom variant="subtitle1">
                iOS
              </Typography>
              <QRCode value={IOS_LINK} />
              <Button
                fullWidth
                size="small"
                startIcon={<ContentCopyIcon />}
                sx={{ textTransform: 'none', color: 'black' }}
                variant="text"
                onClick={() => {
                  handleCopyToClipboard(IOS_LINK);
                }}
              >
                Copy Link
              </Button>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ border: '1px solid #D7DAFE', borderRadius: '12px' }}>
              <Typography gutterBottom variant="subtitle1">
                Android
              </Typography>
              <QRCode value={ANDROID_LINK} />
              <Button
                fullWidth
                endIcon={<ContentCopyIcon />}
                size="small"
                sx={{ textTransform: 'none', color: 'black' }}
                variant="text"
                onClick={() => {
                  handleCopyToClipboard(ANDROID_LINK);
                }}
              >
                Copy Link
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={isChecked} size="small" onChange={handleCheckboxChange} />}
            label="I have created a personal key in dMPC approval app"
            slotProps={{ typography: { sx: { fontSize: 14 } } }}
          />
          <Button
            fullWidth
            disabled={!isChecked}
            sx={{ borderRadius: '24px', textTransform: 'none', mt: 2 }}
            variant="contained"
            onClick={handleNext}
          >
            Next
          </Button>
        </Grid>
        <Snackbar autoHideDuration={3000} open={isSnackbarOpen} onClose={handleCloseSnackbar}>
          <Alert severity="success" sx={{ width: '100%' }} onClose={handleCloseSnackbar}>
            Link is copied
          </Alert>
        </Snackbar>
      </Grid>
    </SignupLayout>
  );
};

export default SecureAccount;
