import { Box, Button, Dialog, IconButton, Typography } from '@mui/material';

import { QrCode, ContentCopy } from '@mui/icons-material';

import { useState } from 'react';

import SendDialog from './components/SendDialog';

import { useCreateTransactionMutation, type TCreateTransactionRequest, type TWallet } from '../../../redux/api';

import type React from 'react';

const WalletDetails: React.FC<{ wallet: TWallet }> = ({ wallet }) => {
  const [isOpen, setOpen] = useState(false);

  const [createTransaction, { isLoading }] = useCreateTransactionMutation();

  const handleClose = () => {
    setOpen(false);
  };

  const handleSend = (data: Omit<TCreateTransactionRequest, 'WalletID'>) => {
    setOpen(false);
    createTransaction({ ...data, WalletID: wallet.walletId });
  };

  return (
    <Box
      sx={{ px: 3, py: 2, background: 'white', borderRadius: 2, display: 'flex', gap: 1.5, flexDirection: 'column' }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
        <Typography variant="h6">DeFi wallet</Typography>
        <Typography sx={{ fontSize: '12px', fontWeight: '500', color: '#439270' }}>Connected</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
        <Typography noWrap sx={{ textTransform: 'none', fontSize: '14px', color: '#616161' }} title={wallet.walletId}>
          {wallet.walletId.slice(0, 8)}...{wallet.walletId.slice(-4)}
        </Typography>
        <QrCode sx={{ width: 16, height: 16 }} />
        <ContentCopy sx={{ width: 16, height: 16 }} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
        <Typography sx={{ fontSize: '14px', color: '#141414' }}>View balance</Typography>
        <IconButton sx={{ p: 0 }}>
          <img height="24" src="/images/image_98.png" width="24"></img>
        </IconButton>
        <IconButton sx={{ p: 0 }}>
          <img height="24" src="/images/image_99.png" width="24"></img>
        </IconButton>
        <IconButton sx={{ p: 0 }}>
          <img height="24" src="/images/Frame_10474.png" width="24"></img>
        </IconButton>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center', mt: 2 }}>
        <Button sx={{ textTransform: 'none' }} variant="contained">
          Deposit
        </Button>
        <Button
          disabled={isLoading}
          sx={{ textTransform: 'none' }}
          variant="outlined"
          onClick={() => {
            setOpen(true);
          }}
        >
          {isLoading ? 'Sending...' : 'Send'}
        </Button>
      </Box>
      <Dialog
        PaperProps={{
          sx: { borderRadius: 3, width: '100%' },
        }}
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        open={isOpen}
        onClose={handleClose}
      >
        <SendDialog onClose={handleClose} onSend={handleSend} />
      </Dialog>
    </Box>
  );
};

export default WalletDetails;
