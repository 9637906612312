import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, TextField, Typography, Paper, Grid, CircularProgress } from '@mui/material';

import { get } from 'lodash';

import { useCreateOrganizationMutation } from '../../redux/api';

import { RegistrationStepsRoutes } from '../constants';

import { useAuthContext } from '../../auth/AuthContext';

import SignupLayout from '../SignupLayout';

import type React from 'react';

import type TFormElement from '../../types/TFormElement';

type TFormElements = {
  organizationName: HTMLInputElement;
};

const CreateOrganization: React.FC = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const [error, setError] = useState('');

  const [createOrganization, { isLoading }] = useCreateOrganizationMutation();

  const handleCreateOrganization = async (event: React.FormEvent<TFormElement<TFormElements>>) => {
    event.preventDefault();

    const {
      organizationName: { value },
    } = event.currentTarget.elements;

    if (!value) {
      return;
    }

    if (!Boolean(value.match(/[a-zA-Z0-9\_ ]{4,}/))) {
      setError('min 4 letters, numbers, underscore');

      return;
    }

    setError('');

    const result = await createOrganization({ loginId: user?.uid ?? '', organizationName: value });

    if ('error' in result) {
      if (typeof result.error === 'string') {
        setError(result.error);

        return;
      }

      //TODO: set proper error type in rtk
      const customError = get<object, string>(result, 'error.data');

      if (typeof customError === 'string') {
        setError(customError);

        return;
      }

      setError('Something went wrong');

      return;
    }

    navigate(RegistrationStepsRoutes.SECURE_ACCOUNT);
  };

  const handleGoBack = () => {
    navigate(RegistrationStepsRoutes.SELECT_ACCOUNT_TYPE);
  };

  return (
    <SignupLayout progress={60} onBack={handleGoBack}>
      <Paper elevation={0} sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="h5">Create organization.</Typography>
        <Typography color="grey" mb={3} variant="body2">
          Create organization name that will be used by the team.
        </Typography>
        <form onSubmit={handleCreateOrganization}>
          <Grid item>
            <TextField
              fullWidth
              disabled={isLoading}
              error={!!error}
              helperText={error}
              id="email"
              label="Enter organization name"
              name="organizationName"
              size="small"
              sx={{
                width: '100%',
                mb: 3,
                borderRadius: '4px',
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <Button
              fullWidth
              startIcon={isLoading ? <CircularProgress size="1em" /> : null}
              sx={{ borderRadius: '24px', textTransform: 'none' }}
              type="submit"
              variant="contained"
            >
              {isLoading ? 'Creating...' : 'Next'}
            </Button>
          </Grid>
        </form>
      </Paper>
    </SignupLayout>
  );
};

export default CreateOrganization;
