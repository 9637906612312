import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { debounce } from '@mui/material/utils';

import Typography from '@mui/material/Typography';

import { Add } from '@mui/icons-material';

import { useSearchUsersQuery } from '../../../../../redux/api';
import FoundUserItem from './FoundUserItem';

import type { TWalletCreateUser } from './ManageAccessStep';

export default function SearchUsersField({ onAddUser }: { onAddUser: (user: TWalletCreateUser) => void }) {
  const [inputValue, setInputValue] = React.useState('');

  const { isFetching, data } = useSearchUsersQuery({ emailOrName: inputValue }, { skip: !inputValue });

  const debouncesChange = debounce(setInputValue, 1000);

  const options = React.useMemo(() => (data ? [data] : []), [data]);

  return (
    <Autocomplete
      blurOnSelect
      filterOptions={(options) => options}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }

        return option.email;
      }}
      id="search-users"
      loading={isFetching}
      noOptionsText={
        !inputValue ? (
          'No results found'
        ) : (
          <Typography
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              onAddUser({ email: inputValue, name: '', role: 'Viewer', invited: true });
            }}
          >
            <Add sx={{ width: 16, height: 16 }} />
            <span>{`Invite ${inputValue}`}</span>
          </Typography>
        )
      }
      options={options}
      renderInput={(params) => (
        <TextField {...params} fullWidth label="Search name or email" placeholder="Type user name or ID" />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <FoundUserItem email={option.email} name={option.nickname} />
          </li>
        );
      }}
      value={null}
      onChange={(event, newInputValue) => {
        if (newInputValue) {
          onAddUser({ email: String(newInputValue.email), name: String(newInputValue.nickname), role: 'Viewer' });
        }
      }}
      onInputChange={(event, newInputValue, reason) => {
        if (reason === 'reset') {
          return '';
        }

        debouncesChange(newInputValue);
      }}
    />
  );
}
