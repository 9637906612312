import { ArrowBack } from '@mui/icons-material';
import { Button, LinearProgress } from '@mui/material';
import Box, { type BoxProps } from '@mui/material/Box';

import type React from 'react';

export type TSignupLayoutProps = {
  progress?: number;
  onBack?: () => void;
} & BoxProps;
const SignupLayout: React.FC<TSignupLayoutProps> = ({ progress, children, onBack, sx, ...boxProps }) => {
  return (
    <Box
      component="main"
      sx={{ maxWidth: 1440, m: 'auto', height: '100dvh', display: 'flex', backgroundColor: 'white', ...sx }}
      {...boxProps}
    >
      {/* content panel*/}
      <Box
        component="section"
        sx={{
          maxWidth: 720,
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'start',
          overflowY: 'auto',
          px: 4,
          py: 10,
        }}
      >
        <Box sx={{ maxWidth: 464, width: '100%', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Box component="header" mb="32px">
            <Box
              alt="Logo"
              component="img"
              height={62}
              src="/images/logo_full.svg"
              sx={{
                filter: 'invert(1)',
              }}
              width={177}
            />
          </Box>
          {!!onBack && (
            <Box component="nav">
              <Button startIcon={<ArrowBack />} sx={{ textTransform: 'none' }} variant="text" onClick={onBack}>
                Back
              </Button>
            </Box>
          )}
          {progress ? <LinearProgress color="primary" value={progress} variant="determinate" /> : null}
          <Box component="section" sx={{ width: '100%', mt: 3, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            {children}
          </Box>
        </Box>
      </Box>
      {/* image panel*/}
      <Box
        component="aside"
        sx={{
          display: { xs: 'none', sm: 'block' },
          maxWidth: 720,
          flexGrow: 1,
          backgroundImage: 'url(/images/login_bg.jpg)',
        }}
      ></Box>
    </Box>
  );
};

export default SignupLayout;
