import {
  DialogTitle,
  DialogContent,
  LinearProgress,
  DialogContentText,
  Select,
  MenuItem,
  DialogActions,
  Button,
  Tooltip,
  Typography,
  Box,
} from '@mui/material';

import { QuestionMarkOutlined } from '@mui/icons-material';

import type { TFormElements } from '../CreateWalletFlow';

import type { TWalletData } from '../../../../redux/api';

import type TCreateWalletStepsProps from '../../../../types/TCreateWalletStepsProps';
import type TFormElement from '../../../../types/TFormElement';

import type React from 'react';

const WalletThreshold: React.FC<TCreateWalletStepsProps & { createWallet: (data: TWalletData) => void }> = ({
  onClose,
  setWalletData,
  walletData,
  createWallet,
}) => {
  return (
    <form
      onSubmit={(event: React.FormEvent<TFormElement<TFormElements>>) => {
        event.preventDefault();

        const { threshold } = event.currentTarget.elements;

        setWalletData?.((data) => {
          const updatedData = { ...data, threshold: threshold.value };

          createWallet(updatedData);

          return updatedData;
        });
      }}
    >
      <DialogTitle id="alert-dialog-title" variant="headlineLarge">
        Create wallet
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <LinearProgress value={95} variant="determinate" />
        <DialogContentText id="alert-dialog-description" my={2} variant="bodyLarge">
          Select your wallet threshold that will unlock and manage wallet executions and signs.
        </DialogContentText>
        <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }} variant="labelLarge">
          Threshold
          <Tooltip
            title={
              <Box>
                <span>
                  1 of 1 - needs single approval before successful execution and only one approver is allowed to do so.
                </span>
                <span>
                  1 of 2 - needs at least a single approval before successful execution, there are 2 approvers, and if
                  at least one of the approvers approves can approve the execution of transactions or sign data.
                </span>
                <span>
                  2/3 - needs 2 at least two approvals before successful execution, and 3 users can approve execution of
                  transactions or sign data.
                </span>
              </Box>
            }
          >
            <QuestionMarkOutlined sx={{ fontSize: 16, borderRadius: 100, border: '1px solid #949494' }} />
          </Tooltip>
        </Typography>
        <Select
          fullWidth
          defaultValue={walletData?.threshold}
          id="demo-simple-select"
          label="Threshold"
          labelId="demo-simple-select-label"
          name="threshold"
        >
          <MenuItem value="1of6">1 of 6</MenuItem>
          <MenuItem value="2of6">2 of 6</MenuItem>
          <MenuItem value="3of6">3 of 6</MenuItem>
          <MenuItem value="4of6">4 of 6</MenuItem>
          <MenuItem value="5of6">5 of 6</MenuItem>
          <MenuItem value="6of6">6 of 6</MenuItem>
        </Select>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button sx={{ ml: 0, mr: 'auto', color: 'grey.100' }} variant="text" onClick={onClose}>
          Close
        </Button>
        <Button autoFocus type="submit" variant="contained">
          Next step
        </Button>
      </DialogActions>
    </form>
  );
};

export default WalletThreshold;
