import { MenuItem, Select, type SelectProps } from '@mui/material';

import { userRoles } from '../../../../../components/constants';

import type React from 'react';

export type TUserRoleSelectProps = {
  onChange: (role: string) => void;
} & Partial<Omit<SelectProps, 'onChange'>>;

const UserRoleSelect: React.FC<TUserRoleSelectProps> = ({ onChange, defaultValue = 'Viewer', ...menuProps }) => {
  const roles = userRoles.map(({ name }) => (
    <MenuItem key={name} value={name}>
      {name}
    </MenuItem>
  ));

  return (
    <Select
      defaultValue={defaultValue}
      id="demo-simple-select"
      labelId="demo-simple-select-label"
      margin="dense"
      sx={{ fontSize: '14px' }}
      {...menuProps}
    >
      {roles}
    </Select>
  );
};

export default UserRoleSelect;
