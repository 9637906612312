import { configureStore, combineSlices } from '@reduxjs/toolkit/react';

import registrationSlice from '../components/Registration/redux/registrationSlice';

import { api } from './api';

import type { Action, ThunkAction } from '@reduxjs/toolkit/react';

export const rootReducer = combineSlices({
  [api.reducerPath]: api.reducer,
  [registrationSlice.reducerPath]: registrationSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([api.middleware]),
});

export type TAppDispatch = typeof store.dispatch;
export type TRootState = ReturnType<typeof store.getState>;
export type TAppThunk<ReturnType = void> = ThunkAction<ReturnType, TRootState, unknown, Action>;
